/* screen breaks */
/** menu heights **/
/* screen breaks */
/** menu heights **/
/** Variables and mixins for screen breaks **/
.zzmedia_landing-page, .zzmedia_all-projects-overview {
  /* screen breaks */
  /** menu heights **/
  /* screen breaks */
  /** menu heights **/
  /** Variables and mixins for screen breaks **/ }
  .zzmedia_landing-page #project-teaser, .zzmedia_all-projects-overview #project-teaser {
    display: flex;
    justify-content: center;
    align-items: center; }
    .zzmedia_landing-page #project-teaser p, .zzmedia_all-projects-overview #project-teaser p {
      text-align: center;
      width: 100%; }
  .zzmedia_landing-page .category-teaser > div > div, .zzmedia_all-projects-overview .category-teaser > div > div {
    lost-waffle: 1/2 2 10px; }
  @media only screen and (min-width: 821px) {
    .zzmedia_landing-page .category-teaser:nth-child(2n-1) > div:first-child, .zzmedia_all-projects-overview .category-teaser:nth-child(2n-1) > div:first-child {
      lost-move: 1/2 row 10px; }
    .zzmedia_landing-page .category-teaser:nth-child(2n-1) > div:last-child, .zzmedia_all-projects-overview .category-teaser:nth-child(2n-1) > div:last-child {
      lost-move: -1/2 row 10px; } }

.zzmedia_landing-page .site-header#menu-small {
  display: flex;
  visibility: hidden; }

.zzmedia_landing-page .site-header#menu-full {
  display: flex; }

.zzmedia_landing-page .site-content-wrapper {
  margin-top: 0;
  padding-top: 0; }

.zzmedia_landing-page section#teaser, .zzmedia_landing-page section#project-teaser, .zzmedia_landing-page section#contact {
  margin-bottom: 80px; }

.zzmedia_landing-page section#projects, .zzmedia_landing-page section#projects-headline, .zzmedia_landing-page section#featured-projects {
  margin-top: 0; }

.zzmedia_landing-page section#featured-projects {
  margin-bottom: 0; }

.zzmedia_landing-page .site-content-wrapper > .moduletable > section, .zzmedia_landing-page .site-content-wrapper > .moduletable > .custom > section {
  margin-bottom: 100px;
  margin-top: 0px; }

.zzmedia_landing-page .site-content-wrapper > .moduletable:first-child > section, .zzmedia_landing-page .site-content-wrapper > .moduletable:first-child > .custom > section {
  margin-bottom: 80px;
  margin-top: 0px; }

.zzmedia_landing-page #featured-projects .category-teaser:last-child {
  margin-bottom: 0; }

@media only screen and (max-width: 639px) {
  .zzmedia_landing-page section#teaser, .zzmedia_landing-page section#project-teaser, .zzmedia_landing-page section#contact {
    margin-bottom: 40px; }
  .zzmedia_landing-page section#projects, .zzmedia_landing-page section#projects-headline, .zzmedia_landing-page section#featured-projects {
    margin-top: 0; }
  .zzmedia_landing-page section#featured-projects {
    margin-bottom: 0; }
  .zzmedia_landing-page .site-content-wrapper > .moduletable > section, .zzmedia_landing-page .site-content-wrapper > .moduletable > .custom > section {
    margin-bottom: 50px;
    margin-top: 0px; }
  .zzmedia_landing-page .site-content-wrapper > .moduletable:first-child > section, .zzmedia_landing-page .site-content-wrapper > .moduletable:first-child > .custom > section {
    margin-bottom: 40px;
    margin-top: 0px; }
  .zzmedia_landing-page #featured-projects .category-teaser:last-child {
    margin-bottom: 0; } }
